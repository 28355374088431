@font-face {
  font-family: 'Bhanschrift';
  src: url('./assets/fonts/BAHNSCHRIFT.TTF') format('truetype');
  font-weight: normal;
  font-style: normal;
}

body {
  font-family: 'Bhanschrift' !important;
}

a {
  text-decoration: none !important;
  color: inherit !important;
}

.slick-prev:before,
.slick-next:before {
  color: black !important;
  font-size: 26px !important;
  opacity: 1 !important;
}

button.navbar-toggler {
  background: #a9f003;
}

.theme-btn {
  background-color: #a9f003;
  font-size: 15px;
  color: black !important;
  padding: 16px;
  border-radius: 3px;
  border: none;
}

header {
  background-color: black !important;
  padding-top: 17px;
  padding-bottom: 22px;
  border-bottom: 1px solid #a9f003;
}

header nav a {
  color: white !important;
}

.righ-side-btns {
  display: flex;
  gap: 15px;
  align-items: center;
}

.righ-side-btns a {
  font-size: 16px;
}

.righ-side-btns a {
  border: 1px solid white;
  padding: 12px 30px;
  border-radius: 6px;
}

.righ-side-btns a:nth-child(2) {
  background: rgb(169, 240, 3);
  border: none;
  color: black !IMPORTANT;
}

img.brand_logo {
  width: 155px;
  max-width: 170px;
  margin-right: 20px;
}

.navbar-expand-lg .navbar-nav {
  gap: 18px;
}

.dropdown-menu a {
  color: #848484 !important;
}

.dropdown-menu {
  padding: 15px 13px;
  border-radius: 0 !important;
  width: 259px;
  top: 62px !important;
  left: 4px !important;
}

section.hero-banner {
  background: black;
  padding-top: 50px;
  padding-bottom: 50px;
  color: white;
}

.banner-cont h1 {
  font-size: 50px;
}

.banner-cont p {
  font-size: 14px;
  width: 65%;
  line-height: 26px;
}

.banner-cont h6 {
  font-size: 24px;
  font-weight: bold;
  padding-top: 9px;
}

.scan-qr {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-top: 25px;
  padding-bottom: 15px;
}

.scan-qr p {
  width: 10%;
  font-size: 18px;
}

.down-app {
  display: flex;
  gap: 23px;
  align-items: end;
}

.down-app h5 {
  font-size: 18px;
  font-weight: 600;
}

.banner-btn {
  display: flex;
  align-items: center;
  padding-top: 25px;
  gap: 28px;
}

.banner-btn a {
  padding: 9px 20px;
  font-size: 16px;
}

.banner-btn a:nth-child(2) {
  padding: 10px 50px;
}

.banner-btn svg {
  width: 28px;
  height: 23px;
}

.banner-video {
  background: white;
  color: black;
  text-align: center;
  height: 100%;
  align-items: center;
  display: flex;
  justify-content: center;
}

.banner-video h2 {
  font-size: 80px;
  text-transform: uppercase;
  font-weight: bold;
}

section.goWithG {
  background: black;
  color: white;
  margin-top: 50px;
  margin-bottom: 50px;
  padding: 60px 0px 60px 0px;
  text-align: center;
}

.gotron_guide {
  padding-top: 40px;
  padding-bottom: 40px;
}

section.goWithG h2 {
  font-size: 49px;
}

.guide-box h4 {
  font-size: 20px;
  font-weight: bold;
  padding-top: 19px;
  padding-bottom: 5px;
}

.guide-box p {
  font-size: 14px;
  font-weight: 700;
}

section.mobility_way {
  background: #f6fee5;
  padding-top: 80px;
  padding-bottom: 80px;
}

.mobil_img h6 {
  text-align: center;
  font-size: 26px;
  font-weight: 600;
}

.mobil_img img {
  width: 100%;
}

.mob_spec ul li {
  list-style-type: none;
  display: flex;
  gap: 12px;
  align-items: end;
  margin-bottom: 16px;
  position: relative;
}

.mob_spec ul li p {
  font-size: 14px;
  font-weight: 600;
}

.mob_spec ul {
  padding: 0;
  padding-top: 15px;
}

.mob_cont H2 {
  font-size: 54px;
  font-weight: 500;
}

.mob_cont p {
  font-size: 14px;
  color: black;
  font-weight: 600;
}

section.our_goal {
  padding-top: 80px;
  padding-bottom: 80px;
  text-align: center;
}

section.our_goal h2 {
  font-size: 48px;
}

section.our_goal h2 span {
  text-decoration: underline;
}

section.our_goal p {
  font-size: 17px;
  color: black;
  padding-top: 30px;
  margin: 0;
  padding-bottom: 14px;
  font-weight: 500;
}

.founder h6 {
  font-weight: bold;
  font-style: italic;
}

section.go_to_option {
  background: #a9f003;
  padding-top: 100px;
  padding-bottom: 100px;
}

.go_to_cont h3 {
  font-weight: bold;
  font-size: 32px;
}

.go_to_cont p {
  font-size: 14px;
  color: black;
  line-height: 24px;
}

.travel_terms {
  text-align: center;
}

.travel_terms h4 {
  font-size: 27px;
  color: black;
}

.travel_terms a {
  background: black;
  color: #a9f003 !important;
  padding: 13px 30px;
  font-size: 60px;
  border-radius: 4px;
  font-weight: bold;
}

section.made_for_members {
  padding-top: 80px;
  padding-bottom: 80px;
  text-align: center;
}

section.made_for_members h2 {
  font-size: 48px;
  padding-bottom: 35px;
}

.mem_cont_box img {
  width: 100px;
}

.mem_cont_box h3 {
  font-size: 21px;
  font-weight: 500;
  color: black;
  padding-top: 11px;
}

.mem_cont_box {
  margin-bottom: 50px;
}

.kenn_cont h2 {
  font-size: 45px;
  color: #a9f003;
  font-weight: bold;
  padding-top: 20px;
  padding-bottom: 7px;
}

.kenn_cont h6 {
  color: black;
  font-size: 20px;
}

.kenn_cont p {
  font-size: 15px;
  color: black;
}

section.keen_on_green img {
  height: 400px;
  object-fit: cover;
}

section.keen_on_green {
  padding-top: 80px;
  padding-bottom: 80px;
}

.faqs_top {
  text-align: center;
  padding-bottom: 25px;
}

.faqs_top h6 {
  background: #a9f003;
  width: 9%;
  display: flex;
  justify-content: center;
  margin: 0 auto;
  padding: 11px 10px;
  font-size: 19px;
  font-weight: bold;
  border-radius: 4px;
  margin-bottom: 25px;
}

.faqs_top h2 {
  font-size: 45px;
}

.faqs_top p {
  font-size: 16px;
  color: black;
}

section.home_faqs {
  padding-top: 50px;
  padding-bottom: 50px;
}

.faqs_con .accordion-item {
  margin-bottom: 25px;
}

.faqs_con .accordion-button:not(.collapsed) {
  background: none;
}

.faqs_con .accordion-item {
  margin-bottom: 25px;
  border-top: 1px solid #ccc !important;
  border-bottom: 1px solid #ccc !important;
  border-left: none !important;
  border-right: none !important;
  border-radius: 0px !important;
  padding: 7px 0px;
}


.accordion-item h2 button {
  font-size: 18px !important;
  font-weight: bold;
}

.accordion-button:not(.collapsed) {
  color: #a9f003 !important;
}

.accordion-button:focus {
  box-shadow: none !important;
}

footer {
  background: rgb(43, 43, 43);
  color: white;
  padding-top: 90px;
  padding-bottom: 50px;
}

.foot_log_con img {
  width: 150px;
  display: flex;
  justify-content: center;
  margin: 0 auto;
}

.foot_log_con h3 {
  font-size: 23px;
  text-align: center;
  padding-top: 35px;
}

.foot_bottom img {
  width: 30%;
}

.foot-head {
  display: flex;
  gap: 21px;
  align-items: flex-start;
  padding-bottom: 26px;
}

.foot-head h3 {
  font-size: 22px;
  text-decoration: underline;
}

.foot_bottom ul {
  padding: 0;
}

.foot_bottom ul li {
  list-style: none;
  padding-bottom: 17px;
}

.foot_in ul li {
  display: flex;
  gap: 10px;
  align-items: baseline;
}

.foot_in ul li p {
  margin: 0;
}

section.footer .container {
  border-bottom: 1px solid;
  padding-bottom: 40px;
}

section.social_foot_cont {
  padding-top: 50px;
}

.down {
  display: flex;
  gap: 18px;
}

.down img {
  width: 160px;
}

ul.soc-icons {
  padding: 0;
  display: flex;
  gap: 24px;
}

ul.soc-icons li {
  list-style: none;
}

ul.soc-icons li svg {
  width: 38px;
  height: 42px;
  background: #6a6a6a87;
  padding: 4px;
  border-radius: 2px;
}

section.copyright {
  background: #a9f003;
  padding: 20px;
  text-align: center;
}

section.copyright p {
  margin: 0;
  font-size: 14px;
  font-weight: bold;
}


.rvol_cont {
  background-image: url('./assets/img/rev_sec_bg.png');
  background-size: auto;
  background-repeat: no-repeat;
  background-position: right;
  background-color: black;
  color: white;
  padding-top: 80px;
  padding-bottom: 80px;
}

.revoluting_rides {
  background-color: black;
  color: white;
  padding-top: 40px;
}

section.revoluting_rides h1 {
  text-align: center;
  font-size: 57px;
}

.rev_det_box {
  background: #a9f003d1;
  padding: 40px;
  margin-bottom: 35px;
  height: 94%;

}

.rev_det_box h4 {
  font-size: 33px;
}

.rev_det_box p {
  margin: 0;
  padding-top: 13px;
  font-size: 15px;
  line-height: 26px;
}

section.experience_exc {
  padding-top: 80px;
  padding-bottom: 80px;
  text-align: center;
}

section.experience_exc h2 {
  font-size: 50px;
  text-align: center;
  padding-bottom: 12px;
}

section.experience_exc p {
  font-size: 17px;
  padding-bottom: 35px;
  color: black;
}

.ex_b>* {
  padding-bottom: 16px;
}

.ex_b h3 {
  margin: 0 !important;
  font-size: 21px;
}

.ex_b p {
  color: #474747 !important;
  font-size: 16px !important;
}


section.letsgothere {
  color: white;
  padding-top: 12%;
  padding-bottom: 12%;
  background-image: url('./assets/img/lets_go_there_bg.png');
  background-size: contain;
  background-repeat: no-repeat;
  background-color: black;
  background-position: left;
}

.let_con h2 {
  font-size: 50px;
}

.let_con p {
  font-size: 17px;
  padding-bottom: 10px;
}

section.gotronapp {
  padding-top: 80px;
}

section.gotronapp .sec_top_co h2 {
  font-size: 55px;
  padding-bottom: 15px;
}

section.gotronapp .sec_top_co h3 {
  font-size: 40px;
  margin-bottom: 0;
}

section.gotronapp .sec_top_co p {
  font-size: 18px;
  color: black;
}

.app_steps_box img {
  width: 100%;
  height: 400px;
  object-fit: contain;
}

.step-cont h3 {
  font-size: 34px;
  /* font-weight: 600; */
  padding-bottom: 8px;
}

.step-cont ul li::marker {
  font-size: 26px;
  color: #a9f003;
  border: 1px solid black;
  color: #a9f003;
}

.step-cont ul li {
  font-size: 18px;
  padding-bottom: 4px;
  color: black;
}

.app_steps_box {
  padding-top: 80px;
  padding-bottom: 80px;
}